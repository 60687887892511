import React, { useEffect, useState } from "react";
import ProductInfo from "./ProductInfo";
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function ProductSettings(props) {
  //* Get the product Data
  const { id } = useParams();

  const { business } = props;

  const [product, setProduct] = useState();

  useEffect(() => {
    getProductData();
  }, [id]);

  const getProductData = async () => {
    try {
      const response = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/restaurant/dish/${business.id}/dish/${id}`
      );
      setProduct(response.data);
    } catch (error) {
      console.log("Error getting product data: ", error);
    }
  };

  return (
    <>
      <ListBackBtn backURL="/business/menu" btnTxt="Back To Menu Item List" />
      {product !== undefined && (
        <ProductInfo product={product.data} business={business} />
      )}
    </>
  );
}
