import { Route, Routes } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUser } from "./features/reducers/user";
import { getCookie } from "./common/manageCookies";
import { Helmet } from "react-helmet";

// Importar los componentes necesarios
import BusinessDashboard from "./routes/BusinessDashboard/BusinessDashboard";
import PageNotFound from "./common/PageNotFound";
import LoginPage from "./routes/Login/LoginPage";
import AccountVerification from "./routes/Login/components/AccountVerification";
import PrivateRoute from "./common/PrivateRoute";
import UserSettings from "./routes/UserDashboard/UserSettings";
import AffiliateDashboard from "./routes/AffiliateDashboard/AffiliateDashboard";
import LogoutPage from "./routes/Login/LogoutPage";
import RestrictedContent from "./common/RestrictedContent";
import BizzllManagement from "./routes/BizzllManagement/BizzllManagement";
import ResetPasswordPage from "./routes/Login/ResetPasswordPage";
import ReferralHandler from "./routes/Login/ReferralHandler";
import NotificationManager from "./common/ManageNotifications";
import FloatingButton from "./common/FloatingButton";
import EmbeddedJitsiMeet from "./routes/BusinessDashboard/pages/JitsiMeet/EmbeddedJitsiMeet";
import CustomerCheckout from "./routes/CustomerCheckout/CustomerCheckout";

function App() {
  const dispatch = useDispatch();
  const getToken = getCookie("ubt");

  useEffect(() => {
    if (getToken) {
      dispatch(getUser());
    }
  }, [dispatch]);

  return (
    <div className="App">
      <FloatingButton />
      <Helmet>
        <title>BIZZLL Dashboard</title>
      </Helmet>

      <Routes>
        <Route path="/" element={<LoginPage token={getToken} />} exact />
        <Route path="/password-reset/:token" element={<ResetPasswordPage />} />
        <Route path="/verify/*" element={<AccountVerification />} exact />
        <Route
          path="/business/*"
          element={
            <PrivateRoute>
              <BusinessDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/account/*"
          element={
            <PrivateRoute>
              <UserSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/affiliate/*"
          element={
            <PrivateRoute>
              <AffiliateDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/bizzll-management/*"
          element={
            <PrivateRoute>
              <BizzllManagement />
            </PrivateRoute>
          }
        />
        <Route path="/logout/*" element={<LogoutPage />} />
        <Route path="/ref/:code" element={<ReferralHandler />} />

        <Route
          path="/jitsi-meet/:appointmentId"
          element={<EmbeddedJitsiMeet />}
        />

        {/* Shopping Routes */}
        <Route
          element={
            <PrivateRoute>
              <CustomerCheckout />
            </PrivateRoute>
          }
          path="/checkout/*"
        />

        <Route path="/restricted-page" element={<RestrictedContent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <NotificationManager />
    </div>
  );
}

export default App;
