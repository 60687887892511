//** Import Modules */
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import DashboardSidebar from "./components/DashboardSidebar";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//** Import Components */
import SettingsPageError from "./pages/SettingsPageError";
import BusinessInfo from "./pages/BusinessInfo";
import LoadingPage from "../../common/LoadingPage";
import BusinessDescription from "./pages/BusinessDescription";
import BusinessProfile from "./pages/BusinessProfile";
import HeaderUserBar from "../../common/HeaderUserBar/HeaderUserBar";
import ManageGroups from "./pages/ManageGroups/ManageGroups";
import BusinessFollowers from "./pages/BusinessFollowers/BusinessFollowers";
import PrivateMessages from "./pages/PrivateMessages/PrivateMessages";
import ManageEvents from "./pages/ManageEvents/ManageEvents";
import ManageService from "./pages/ManageService/ManageService";
import ManageAppointments from "./pages/ManageAppointments/ManageAppointments";
import ManageProduct from "./pages/ManageRestaurants/ManageProduct";
import ManageCategories from "./pages/ManageRestaurants/ManageCategories";
import ManageOrders from "./pages/ManageRestaurants/ManageOrders";
import ManageAds from "./pages/ManageAds/ManageAds";
import Home from "./pages/Home";
import ManageAnalytics from "./pages/ManageAnalytics/ManageAnalytics";
import ManageReviews from "./pages/ManageReviews/ManageReviews";
import PrivateInternalRoute from "../../common/PrivateInternalRoute";
import PayoutSettings from "./pages/PayoutSettings/PayoutSettings";
import ClaimBusiness from "./pages/ClaimBusiness";

//** Import Reducers */
import { getBusiness, getBusinessList } from "../../features/reducers/business";

//** Separate antd Layout Components */
const { Header, Content, Footer } = Layout;

export default function BusinessDashboard() {
  // Setup the base url
  const baseURL = "/business";

  // Setup States
  const [collapsed, setCollapsed] = useState(false);

  // Initiate action to get user's business list
  const dispatch = useDispatch();
  const isPageLoading = useSelector((state) => state.business.loadingPage);
  const businessList = useSelector((state) => state.business.businessList);
  const business = useSelector((state) => {
    return state.business.business;
  });

  useEffect(() => {
    dispatch(getBusinessList());
  }, [dispatch]);

  // Set the current business to edit
  useEffect(() => {
    const preSelectedBusiness = localStorage.getItem("editBusiness");

    if (businessList.length > 0) {
      const businessID = preSelectedBusiness
        ? preSelectedBusiness
        : businessList[0].id;

      dispatch(getBusiness(businessID));
    }
  }, [businessList, dispatch]);

  return (
    <>
      <Layout hasSider>
        <DashboardSidebar
          baseURL={baseURL}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />

        <Layout
          className="dashboard-page-layout"
          style={{ marginLeft: collapsed ? 100 : 300 }}
        >
          <Header id="dashboard-header">
            <HeaderUserBar />
          </Header>

          <Content id="dashboard-content">
            <Routes>
              {/* Home Route */}
              <Route path="/" element={<Home business={business} />} exact />

              {/* Main Business Info Forms */}
              <Route
                path="/business-information"
                element={<BusinessInfo business={business} />}
              />
              <Route
                path="/business-description"
                element={<BusinessDescription business={business} />}
              />
              <Route
                path="/profile-settings"
                element={<BusinessProfile business={business} />}
              />

              {/* Networking Routes */}
              <Route
                path="/groups/*"
                element={<ManageGroups business={business} />}
              />
              <Route
                path="/followers"
                element={<BusinessFollowers business={business} />}
              />
              <Route
                path="/messages/*"
                element={<PrivateMessages business={business} />}
              />
              <Route
                path="/reviews/*"
                element={<ManageReviews business={business} />}
              />

              {/* Marketing Routes */}
              <Route
                path="/events/*"
                element={
                  <PrivateInternalRoute capability="access_networking_plus">
                    <ManageEvents business={business} />
                  </PrivateInternalRoute>
                }
              />
              <Route
                path="/advertisements/*"
                element={<ManageAds business={business} />}
              />

              <Route
                path="/menu/*"
                element={<ManageProduct business={business} />}
              />
              <Route
                path="/product_categories/*"
                element={<ManageCategories business={business} />}
              />

              <Route
                path="/orders/*"
                element={<ManageOrders business={business} />}
              />

              {/* Services Routes */}
              <Route
                path="/services/*"
                element={<ManageService business={business} />}
              />

              {/* Appointments Routes */}
              <Route
                path="/appointments/*"
                element={<ManageAppointments business={business} />}
              />

              {/* Analytics Route */}
              <Route
                path="/analytics"
                element={<ManageAnalytics business={business} />}
              />

              {/* Payout Routes */}
              <Route
                path="/payout-settings"
                element={
                  <PrivateInternalRoute capability="access_networking_plus">
                    <PayoutSettings business={business} baseURL={baseURL} />
                  </PrivateInternalRoute>
                }
              />

              {/* Extra Routes */}
              <Route path="/claim-business" element={<ClaimBusiness />} />

              {/* Error Pages */}
              <Route path="*" element={<SettingsPageError />} />
            </Routes>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Bizzll © 2024
          </Footer>
        </Layout>
      </Layout>

      {isPageLoading && <LoadingPage />}
    </>
  );
}
