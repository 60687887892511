//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, DatePicker, Divider, Input, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import { getAd } from "../../../../features/reducers/ads";
import { useParams } from "react-router";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../../common/LoadingForm";
import ListBackBtn from "../../components/ListBackBtn";
import { DATE_FORMAT } from "../../../../common/constants";

export default function AdSettings() {
  // Used to build the form
  const [form] = Form.useForm();

  //* Get the ad Data
  const { id } = useParams();

  // Initiate action to get event data
  const dispatch = useDispatch();
  const ad = useSelector((state) => state.ads.ad);

  useEffect(() => {
    dispatch(getAd(id));
  }, [dispatch, id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(ad);

  useEffect(() => {
    if (ad?.id) {
      form.resetFields();
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ad]);

  // Change aspect ratio of image depending on what type of ad you choose
  const [adSize, setAdSize] = useState(1);

  const chooseAdSize = (value) => {
    if (value === "skyscraper") {
      setAdSize(0.2);
    } else if (value === "leaderboard") {
      setAdSize(8);
    } else {
      setAdSize(1);
    }
  };

  return (
    <div>
      <ListBackBtn
        backURL="/business/advertisements"
        btnTxt="Back To Advertisements List"
      />
      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={fields}
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            values.meta_type = "free";
            submitForm(
              values,
              `/api/advertisement/update/${ad.id}`,
              getAd(ad.id)
            );
          }}
          disabled={isSubmitting}
        >
          <ContentFormSubtitle title="Main Description" />

          <Form.Item
            label="Advertisement Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your ad name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="meta_ad_image">
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_ad_image: file,
                })
              }
              maxCount={1}
              label="Advertisement Image"
              aspectRatio={adSize}
              fieldName="meta_ad_image"
              form={form}
            />
          </Form.Item>

          {/* <Form.Item
            label="Advertisement Space"
            name="meta_ad_banner_type"
            rules={[
              {
                required: true,
                message: "Please choose a advertisement space!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              onSelect={chooseAdSize}
              options={[
                { key: "square", value: "Square (250px * 250px)" },
                { key: "skyscraper", value: "Skyscraper (120px * 600px)" },
              ]}
            />
          </Form.Item> */}

          <ContentFormSubtitle title="Date Settings" />

          <div className="form-row">
            <Form.Item
              label="Start Date"
              name="meta_ad_startdate"
              rules={[
                {
                  required: true,
                  message: "Please input your Start Date!",
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <Form.Item
              label="Months"
              name="meta_ad_month_quantity"
              rules={[
                {
                  required: true,
                  message: "Please input an amount of months!",
                },
              ]}
            >
              <InputNumber addonAfter="month(s)" defaultValue={1} min={1} />
            </Form.Item>
          </div>

          {/* <Divider />

          <ContentFormSubtitle title="Purpose" />

          <Form.Item
            label="Advertise Type"
            name="meta_ad_type"
            rules={[
              {
                required: true,
                message: "Please choose an Advertise Type!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              options={[
                { key: "business", value: "Promote Business" },
                { key: "special", value: "Promote Special" },
              ]}
            />
          </Form.Item> */}

          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
