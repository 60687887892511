//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Divider } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import ContentPageTitle from "../../../common/ContentPageTitle";
import SelectDropdown from "../../../common/FormInputs/SelectDropdown";
import { dashboardGetCities } from "../data/dashboardGetListData";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";
import UploadMedia from "../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../common/LoadingForm";
import SwitchSlider from "../../../common/FormInputs/SwitchSlider";
import {
  validateMaxLength,
  validateEmail,
  validateNumbers,
  validateLength,
  validateAddress,
} from "../../../utils/validations";

export default function BusinessInfo(props) {
  const { business } = props;

  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(business);

  useEffect(() => {
    if (business.id !== undefined) {
      setFieldData();
    }
  }, [business]);

  // Used to get dropdown menu data
  const [cities, setCities] = useState([]);

  useEffect(() => {
    dashboardGetCities(setCities);
  }, []);

  return (
    <div id="business-info">
      {business.activated === 1 && (
        <ContentPageTitle title="Business Basic Information" icon="briefcase" />
      )}

      <div className="content-box">
        {loadingForm ? (
          <LoadingForm />
        ) : (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={fields}
            onFinishFailed={onFinishFailed}
            onFinish={(values) => {
              console.log("values", values);
              values.meta_business_email_public =
                values.meta_business_email_public ? 1 : 0;
              submitForm(values, `/api/business/${business.id}`);
            }}
            disabled={isSubmitting}
          >
            <ContentFormSubtitle title="Basic Information" />

            <Form.Item
              name="meta_business_logo"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload an image!",
              //   },
              // ]}
            >
              <UploadMedia
                setMedia={(file) =>
                  form.setFieldsValue({
                    meta_business_logo: file,
                  })
                }
                maxCount={1}
                label="Business Logo"
                fieldName="meta_business_logo"
                form={form}
                description="400px * 400px Image - Max Size 10MB"
              />
            </Form.Item>

            <div className="form-row">
              <Form.Item
                label="Business Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your business name!",
                  },
                  { validator: (_, value) => validateMaxLength(_, value, 40) },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="meta_business_phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                  { validator: validateNumbers },
                  { validator: (_, value) => validateLength(_, value, 10) },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="meta_business_email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  { validator: validateEmail },
                  { validator: (_, value) => validateMaxLength(_, value, 40) },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Make Email Public?"
                tooltip="You can choose to keep your business email private"
                name="meta_business_email_public"
                valuePropName="checked"
              >
                <SwitchSlider
                  form={form}
                  fieldName="meta_business_email_public"
                />
              </Form.Item>
            </div>

            <Form.Item
              label="FEI/EIN Number"
              name="meta_business_fei_ein_number"
              rules={[
                {
                  required: true,
                  message: "Please input FEI/EIN number",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <ContentFormSubtitle title="Business Address" />

            <div className="form-row">
              <Form.Item
                label="Address"
                name="meta_business_address"
                rules={[
                  {
                    required: true,
                    message: "Please input your business address!",
                  },
                  { validator: validateAddress },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Address 2"
                name="meta_business_address_2"
                tooltip="i.e. Suite # 123, Unit 123"
                rules={[{ validator: validateAddress }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="City"
                name="meta_business_city"
                rules={[
                  {
                    required: true,
                    message: "Please choose a city!",
                  },
                ]}
              >
                <SelectDropdown
                  showSearch={true}
                  options={
                    cities && cities.length
                      ? cities.map((data) => {
                          return {
                            key: data.name,
                            value: data.name,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>

              <Form.Item
                label="State"
                name="meta_business_state"
                rules={[
                  {
                    required: true,
                    message: "Please choose a state!",
                  },
                ]}
              >
                <SelectDropdown
                  showSearch={true}
                  options={[{ key: "Florida", value: "FL" }]}
                />
              </Form.Item>

              <Form.Item
                label="ZIP Code"
                name="meta_business_zip"
                rules={[
                  {
                    required: true,
                    message: "Please input your business ZIP code!",
                  },
                  { validator: validateNumbers },
                  { validator: (_, value) => validateLength(_, value, 5) },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Country"
                name="meta_business_country"
                rules={[
                  {
                    required: true,
                    message: "Please choose a country!",
                  },
                ]}
              >
                <SelectDropdown
                  showSearch={true}
                  options={[{ key: "United States", value: "USA" }]}
                />
              </Form.Item>
            </div>

            <Divider />

            <ContentFormSubtitle title="Business Registered Agent" />

            <Form.Item
              label="Agent Name"
              name="meta_business_agent_name"
              rules={[
                {
                  required: true,
                  message: "Please input a name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Agent Title"
              name="meta_business_agent_title"
              rules={[
                {
                  required: true,
                  message: "Please input a title",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Agent Address"
              name="meta_agent_address"
              rules={[
                {
                  required: true,
                  message: "Please input an address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
