import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Divider,
  Select,
  InputNumber,
  Checkbox,
  Button,
} from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function ProductInfo(props) {
  const { product, business } = props;

  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(product);

  const [productCategories, setProductCategories] = useState([]);
  const [quantityDisabled, setQuantityDisabled] = useState(false);
  const [onOfferEnabled, setOnOfferEnabled] = useState(false);

  const onFinish = async (values) => {
    submitForm(
      values,
      `/api/restaurant/dish/${business.id}/dish/${product.id}`
    );
  };

  useEffect(() => {
    if (product?.id && business?.id) {
      getCategoriesList();
      setFieldData();
      setOnOfferEnabled(!!product.on_offer);
      setQuantityDisabled(product.availability_update_method === "manual");
    }
  }, [product, business]);

  const getCategoriesList = async () => {
    try {
      if (!business?.id) {
        console.error("Business ID is not defined");
        return;
      }
      const APIURL = `/api/restaurant/dish/categories/${business.id}`;
      const res = await axiosWithAuth().get(APIURL);
      setProductCategories(res.data.data || []);
    } catch (error) {
      console.error(
        "Error getting categories: ",
        error.response?.data || error.message
      );
    }
  };

  const handleAvailabilityChange = (value) => {
    if (value === "manual") {
      form.setFieldsValue({
        available_quantity: 0,
      });
      setQuantityDisabled(true);
    } else {
      setQuantityDisabled(false);
    }
  };

  const isAvailable = (e) => {
    // setIsFreeTicket(e.target.checked);

    if (e.target.checked) {
      form.setFieldsValue({
        is_available: true,
      });
    } else {
      form.setFieldsValue({
        is_available: false,
      });
    }
  };

  const handleOfferChange = (e) => {
    if (e.target.checked) {
      setOnOfferEnabled(true);
    } else {
      setOnOfferEnabled(false);
    }
  };

  return (
    <>
      <ContentPageTitle title="Manage Product" icon="pen-to-square" />

      <Divider />
      {loadingForm ? (
        <LoadingForm />
      ) : (
        <div id="restaurants">
          <Form
            form={form}
            layout="vertical"
            initialValues={fields}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <div className="form-row">
              <div className="form-column-left">
                <Form.Item
                  name="dish_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input product's name",
                    },
                  ]}
                >
                  <Input placeholder="Menu Item Name" />
                </Form.Item>

                <Form.Item
                  name="dish_description"
                  rules={[
                    {
                      required: true,
                      message: "Please input menu item's description",
                    },
                  ]}
                >
                  <TextArea placeholder="Description" />
                </Form.Item>

                <Form.Item
                  label="Price"
                  name="dish_price"
                  rules={[
                    {
                      required: true,
                      message: "Please input menu item's price",
                    },
                  ]}
                >
                  <InputNumber addonBefore="$" placeholder="1.00" min={0} />
                </Form.Item>

                <Form.Item
                  label="Category"
                  name="dish_category_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select product's category",
                    },
                  ]}
                >
                  {/* <Select
                  style={{ width: 150 }}
                  placeholder="Select"
                  options={[{ value: 5, label: "breakfast" }]}
                /> */}
                  <SelectDropdown
                    showSearch={true}
                    options={
                      productCategories && productCategories.length
                        ? productCategories.map((data) => {
                            console.log("data", data);
                            return {
                              key: data.id,
                              value: data.category,
                            };
                          })
                        : []
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Availability Method"
                  name="availability_update_method"
                  rules={[
                    {
                      required: true,
                      message: "Please select availability",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="Select"
                    onChange={handleAvailabilityChange}
                    options={[
                      { value: "inventory", label: "inventory" },
                      { value: "manual", label: "manual" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="is_available"
                  label="Is Available"
                  valuePropName="checked"
                >
                  <Checkbox onChange={isAvailable} />
                </Form.Item>
                <Form.Item
                  label="Available quantity"
                  name="available_quantity"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input product's quantity",
                  //   },
                  // ]}
                >
                  <InputNumber
                    placeholder="1"
                    min={0}
                    disabled={quantityDisabled}
                  />
                </Form.Item>
                <Form.Item
                  name="on_offer"
                  label="On offer"
                  valuePropName="checked"
                >
                  <Checkbox onChange={handleOfferChange} />
                </Form.Item>
                <Form.Item
                  label="Offer Price"
                  name="dish_offer_price"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: true,
                      validator(_, value) {
                        if (
                          getFieldValue("on_offer") &&
                          (value === undefined || value === null)
                        ) {
                          return Promise.reject(
                            new Error("Please input the offer price")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    addonBefore="$"
                    placeholder="1.00"
                    min={0}
                    disabled={!onOfferEnabled}
                  />
                </Form.Item>
              </div>

              <div className="form-column-right">
                <Form.Item
                  name="dish_image"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload an image!",
                  //   },
                  // ]}
                >
                  <UploadMedia
                    setMedia={(file) =>
                      form.setFieldsValue({
                        dish_image: file,
                      })
                    }
                    maxCount={1}
                    label="Product image"
                    fieldName="dish_image"
                    form={form}
                    description="400px * 400px Image - Max Size 10MB"
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="ant-btn ant-btn-primary"
                name="button_submit"
                loading={isSubmitting}
              >
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
