import React, { useEffect, useState } from "react";
import { Tabs, Alert } from "antd";
import EventInfo from "./EventInfo";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import EventTickets from "./EventTickets";
import ListBackBtn from "../../components/ListBackBtn";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function EventSettings() {
  //* Get the event Data
  const { id } = useParams();

  const business = useSelector((state) => state.business.business);

  const [event, setEvent] = useState();

  useEffect(() => {
    getEventData();
  }, [id]);

  const getEventData = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/event/${id}`
    );

    const tickets = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/getEventTickets/${id}`
    );

    setEvent({ ...response.data, tickets: [...tickets.data] });
  };

  // Set up publishing warning
  const [hasTickets, setHasTickets] = useState(false);

  useEffect(() => {
    if (event !== undefined && event.tickets.length > 0) {
      setHasTickets(true);
    }
  }, [event]);

  return (
    <>
      <ListBackBtn backURL="/business/events" btnTxt="Back To Events List" />

      {!hasTickets && (
        <div className="warning-boxes">
          <Alert
            message="No tickets"
            description="Before your event is published on the site, you MUST have at least one ticket type added for your event."
            type="error"
            showIcon
          />
        </div>
      )}

      <div className="warning-boxes">
        <Alert
          message="Online Events"
          description={
            <>
              We currently use Jitsi Meet to broadcast online events. To join an
              event, simply go to the event management section and click the
              "Join" button when the scheduled time arrives. Alternatively, you
              can join directly from the event page by clicking the "Join Now"
              button. This will automatically start the meeting and have
              everything ready to begin without any additional setup.
              {/* Alternatively, you may{" "}
              <a
                href="https://zoom.us/meeting#/upcoming"
                target="_blank"
                rel="noreferrer"
              >
                Click Here
              </a>{" "}
              to start a meeting via the web portal(go to "Personal Room", and
              click on "Start") */}
            </>
          }
          type="warning"
          showIcon
        />
      </div>
      {event !== undefined && (
        <Tabs defaultActiveKey="1" type="card" size="default">
          <Tabs.TabPane tab="Event Information" key="1">
            <EventInfo event={event} business={business} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Event Tickets" key="2">
            <EventTickets event={event} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
}
