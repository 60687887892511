import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManageAds } from "../../../../hooks/useManageAds";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { getAdsList } from "../../../../features/reducers/ads";
import AdSettings from "./AdSettings";
import AdsAnalytics from "../ManageAnalytics/AdsAnalytics";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function ManageAds(props) {
  const { business } = props;

  // const dispatch = useDispatch();
  // const adsList = useSelector((state) => {
  //   return state.ads.adsList;
  // });

  const [adsList, setAdsList] = useState([]);

  useEffect(() => {
    getAds();
  }, [business]);

  const getAds = async () => {
    try {
      const response = await axiosWithAuth().get(
        `/api/advertisement/business/${business.id}`
      );
      setAdsList(response.data);
    } catch (error) {
      console.error("Error getting user ads:", error);
    }
  };

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    type: "free",
    isForBusiness: true,
    dispatchAction: getAds,
    itemType: "advertisement",
    deleteItemAPI: "/api/advertisement/delete",
    business: business,
    modalData: {
      title: "Create New Ad",
      fieldLabel: "Ad Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageAds(hookData);

  return (
    <div>
      <ContentPageTitle title="Advertisement" icon="users" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an advertisements."
                  btnText="Create New Ad"
                  listItems={adsList}
                />

                <CreateItemButton btnText="Create New Ad" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<AdSettings />} exact />

          <Route path="/:id/insights" element={<AdsAnalytics />} />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
