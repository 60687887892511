import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, message, Steps, Calendar, Alert } from "antd";
import { DASHBOARD_URL } from "../../../common/constants";

import moment from "moment";
import ListBackButton from "../../../../src/common/ListBackButton";

export function AppointmentsUserSettings(props) {
  //* Get the appointment Data
  const { id } = useParams();

  const [business, setBusiness] = useState("");
  const [appointment, setAppointment] = useState([]);
  const [turns, setTurns] = useState([]);
  const [EnabledDaysCalendar, setEnabledDaysCalendar] = useState([]);
  const [dailyAppointments, setDailyAppointments] = useState([]);

  const [SelectedTurnDay, setSelectedTurnDay] = useState(() =>
    (moment()._d + "").substring(4, 15)
  );
  const [SelectedService, setSelectedService] = useState(null);
  const [SelectedTurn, setSelectedTurn] = useState(null);

  // Get Router info
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const APIEndPoint = `/api/appointments/data/${id}`;
    axiosWithAuth()
      .get(APIEndPoint)
      .then((res) => {
        setAppointment(res.data);
        setBusiness(res.data.business_id);
        setSelectedService(res.data.product);
        disableDays(res.data.product);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  /* GET DAILY APPOINTMENTS LIST */
  const getDailyAppointments = (newValue) => {
    const day = newValue.format("DD");
    const month = newValue.format("MMM");
    const year = newValue.format("YYYY");
    const business_id = business;

    const APIURL = `/api/appointments/list/daily`;

    const data = {
      day,
      month,
      year,
      business_id,
    };

    console.log("data", data);

    axiosWithAuth()
      .post(APIURL, data)
      .then((res) => {
        let temp = res.data.map((element) => {
          return moment(element.start_time).format("HH:mm:ss");
        });
        setDailyAppointments(temp);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  /* Calendar */
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());

  const onSelect = (newValue) => {
    getDailyAppointments(newValue);
    setValue(newValue);
    setSelectedTurnDay(newValue.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (SelectedService != null) {
      createTurnList(SelectedService, value);
    }
  }, [dailyAppointments, SelectedService]);

  const selectTurn = (id) => {
    document
      .querySelectorAll(".single-turn-container_select")
      .forEach((element) => element.classList.remove("selected_turn"));
    document.getElementById(id).classList.add("selected_turn");
    turns.map((turn) => {
      if (turn.TurnId == id) {
        setSelectedTurn(turn);
      }
    });
  };

  const createTurnList = (service, newValue, intervalBetweenTurns = 10) => {
    if (newValue !== undefined) {
      setSelectedTurnDay((newValue._d + "").substring(4, 15));
      setValue(newValue);
      setSelectedValue(newValue);
    }

    var appointmentDuration = service.meta_data.service_duration_time;
    var appointmentDurationHours = Number(appointmentDuration.substr(0, 2));
    var appointmentDurationMinutes = Number(appointmentDuration.substr(3, 2));

    var currentAvailabilityRulesHours = [];
    var currentTurnList = [];

    service.availabilityRules.forEach((availabilityRule) => {
      if (availabilityRule.rule_type === "Range of hours") {
        if (
          newValue !== undefined &&
          availabilityRule.rule_val_1 === newValue.format("dddd").toLowerCase()
        ) {
          currentAvailabilityRulesHours.push(availabilityRule);
        }
      }
    });

    var id = 1;

    const now = moment(); // Momento actual
    const minToMakeAppointment =
      service.meta_data.service_min_to_make_appointment;
    const minToMakeAppointmentPeriod =
      service.meta_data.service_min_to_make_appointment_period;

    currentAvailabilityRulesHours.forEach((currentAvailabilityRulesHour) => {
      var startTime = moment(currentAvailabilityRulesHour.rule_val_2, "HH:mm");
      var temporalTime = moment(
        currentAvailabilityRulesHour.rule_val_2,
        "HH:mm"
      );
      temporalTime = temporalTime.add(appointmentDurationMinutes, "minutes");
      temporalTime = temporalTime.add(appointmentDurationHours, "hours");
      var endTime = temporalTime;

      while (
        endTime.isSameOrBefore(
          moment(currentAvailabilityRulesHour.rule_val_3, "HH:mm")
        )
      ) {
        var insert = true;

        // Verificar la restricción de horas si aplica
        if (minToMakeAppointmentPeriod === "hours") {
          const minAppointmentTime = now
            .clone()
            .add(minToMakeAppointment, "hours");
          if (
            newValue.isSame(now, "day") &&
            startTime.isBefore(minAppointmentTime)
          ) {
            insert = false; // No crear turnos antes del tiempo mínimo permitido
          }
        }

        var turn = {
          TurnId: id,
          // TurnDay: SelectedTurnDay,
          TurnDay: newValue.format("MMM DD YYYY"),
          availableTurns: service.meta_data.service_max_appointments,
          TurnStartTime:
            startTime._d.getHours() +
            ":" +
            ((startTime._d.getMinutes() + "").length > 1
              ? startTime._d.getMinutes()
              : "0" + startTime._d.getMinutes()),
          TurnEndTime:
            endTime._d.getHours() +
            ":" +
            ((endTime._d.getMinutes() + "").length > 1
              ? endTime._d.getMinutes()
              : "0" + endTime._d.getMinutes()),
        };

        if (
          SelectedService.meta_data.service_min_to_make_appointment_period ===
          "hours"
        ) {
          if (
            moment(
              SelectedTurnDay +
                " " +
                startTime._d.getHours() +
                ":" +
                ((startTime._d.getMinutes() + "").length > 1
                  ? startTime._d.getMinutes()
                  : "0" + startTime._d.getMinutes())
            ).format("MM DD YYYY HH:mm") <
            moment()
              .add(
                SelectedService.meta_data.service_min_to_make_appointment,
                "hours"
              )
              .format("MM DD YYYY HH:mm")
          ) {
            insert = false;
          }
        }

        if (insert) {
          currentTurnList.push(turn);
        }

        startTime.add(appointmentDurationMinutes, "minutes");
        startTime.add(appointmentDurationHours, "hours");
        startTime.add(intervalBetweenTurns, "minutes");
        endTime.add(appointmentDurationMinutes, "minutes");
        endTime.add(appointmentDurationHours, "hours");
        endTime.add(intervalBetweenTurns, "minutes");

        id += 1;
      }

      for (let i = currentTurnList.length - 1; i >= 0; i--) {
        const turn = currentTurnList[i];

        // Convertir el tiempo de inicio y finalización del turno a momentos
        const turnStartTime = moment(
          turn.TurnDay + " " + turn.TurnStartTime,
          "MMM DD YYYY HH:mm"
        );
        const turnEndTime = moment(
          turn.TurnDay + " " + turn.TurnEndTime,
          "MMM DD YYYY HH:mm"
        );

        let overlap = false;

        // Verificar solapamiento con turnos reservados
        dailyAppointments.forEach((appointment) => {
          const appointmentStartTime = moment(
            appointment.startTime,
            "MMM DD YYYY HH:mm"
          );
          const appointmentEndTime = moment(
            appointment.endTime,
            "MMM DD YYYY HH:mm"
          );

          // Comprobar si hay solapamiento
          if (
            (turnStartTime.isSameOrAfter(appointmentStartTime) &&
              turnStartTime.isBefore(appointmentEndTime)) ||
            (turnEndTime.isAfter(appointmentStartTime) &&
              turnEndTime.isSameOrBefore(appointmentEndTime)) ||
            (appointmentStartTime.isSameOrAfter(turnStartTime) &&
              appointmentStartTime.isBefore(turnEndTime))
          ) {
            overlap = true;
          }
        });

        // Eliminar el turno si hay solapamiento o es una fecha pasada
        if (
          overlap ||
          moment(turn.TurnDay + " " + turn.TurnStartTime).isBefore(moment())
        ) {
          currentTurnList.splice(i, 1);
        }
      }

      setTurns(currentTurnList);
    });
  };

  const disableDays = (selectedService) => {
    var currentAvailabilityRulesDays = [];
    selectedService.availabilityRules.forEach((availabilityRule) => {
      if (!currentAvailabilityRulesDays.includes(availabilityRule.rule_val_1)) {
        currentAvailabilityRulesDays.push(availabilityRule.rule_val_1);
      }
    });
    console.log("SelectedService", selectedService);
    console.log("currentAvailabilityRulesDays", currentAvailabilityRulesDays);
    setEnabledDaysCalendar(currentAvailabilityRulesDays);
  };

  const steps = [
    {
      content: (
        <div>
          <div className="appointments_container-calendar">
            <div className="appointments_container-calendar_calendar">
              <Alert
                message={`You selected date: ${selectedValue?.format(
                  "YYYY-MM-DD"
                )}`}
              />
              <Calendar
                value={value}
                onSelect={onSelect}
                picker="month"
                disabledDate={(date) => {
                  const currentWeekday = date.format("dddd").toLowerCase();
                  const now = moment();

                  // Deshabilitar si el día de la semana no está habilitado
                  if (!EnabledDaysCalendar.includes(currentWeekday)) {
                    return true;
                  }

                  // Obtener valores de restricciones
                  const minToMakeAppointment =
                    SelectedService.meta_data.service_min_to_make_appointment;
                  const minToMakeAppointmentPeriod =
                    SelectedService.meta_data
                      .service_min_to_make_appointment_period;

                  // Deshabilitar fechas anteriores a la fecha mínima permitida
                  if (minToMakeAppointmentPeriod === "days") {
                    if (
                      date <
                      now.add(minToMakeAppointment, "days").startOf("day")
                    ) {
                      return true;
                    }
                  } else if (minToMakeAppointmentPeriod === "hours") {
                    if (date.isSame(now, "day")) {
                      const minAppointmentTime = now
                        .clone()
                        .add(minToMakeAppointment, "hours");
                      if (date.isBefore(minAppointmentTime)) {
                        return true;
                      }
                    }
                  }

                  // Deshabilitar fechas pasadas
                  if (date && date < now.startOf("day")) {
                    return true;
                  }

                  // Deshabilitar días de baja (day_off)
                  const dayOffs = SelectedService.availabilityRules
                    .filter((rule) => rule.rule_type === "day_off")
                    .map((rule) => moment(rule.rule_val_1, "YYYY-MM-DD"));

                  if (dayOffs.some((dayOff) => dayOff.isSame(date, "day"))) {
                    return true;
                  }

                  return false;
                }}
              />
            </div>
            <div className="appointments_container-calendar_turn">
              {turns.map((turn, index) => {
                return (
                  <div
                    key={turn + "_" + index}
                    className="single-turn-container"
                  >
                    <div
                      className="single-turn-container_select"
                      id={turn.TurnId}
                      onClick={(element) => {
                        selectTurn(element.target.id);
                      }}
                    ></div>
                    <div className="single-turn-container_data">
                      <div className="">
                        <label>
                          {moment(turn.TurnStartTime, "HH:mm").format(
                            "hh:mm A"
                          )}
                        </label>
                      </div>
                      <div className="">
                        <label> {turn.availableTurns} Slot(s) left </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              float: "right",
            }}
          >
            <Button
              type="primary"
              style={{}}
              onClick={() => {
                if (SelectedTurn != null) {
                  // disableDays();

                  // const start_time = moment(SelectedTurn.TurnDay + ' ' + SelectedTurn.TurnStartTime).format("YYYY-MM-DD HH:mm:mm");
                  const start_time = moment(
                    moment(value).format("MMM DD YYYY") +
                      " " +
                      SelectedTurn.TurnStartTime
                  ).format("YYYY-MM-DD HH:mm:mm");
                  const status = "pending";

                  const APIURL = `/api/appointments/update/${id}`;

                  const data = {
                    id,
                    start_time,
                    status,
                  };

                  axiosWithAuth()
                    .put(APIURL, data)
                    .then((res) => {
                      let config = {
                        content: `Appointment updated!`,
                        style: {
                          background: "none",
                        },
                      };
                      message.success(config);
                      window.location.replace(
                        `${DASHBOARD_URL}/account/user_appointments`
                      );
                    })
                    .catch((err) => {
                      console.log(err.response);
                    });

                  console.log("SUBMIT");
                } else {
                  const modal = Modal.error({
                    title: "Error",
                    content: `You must select a turn!`,
                  });

                  setTimeout(() => {
                    modal.destroy();
                  }, 5000);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [current, setCurrent] = useState(0);

  return (
    <>
      <ListBackButton
        backURL="/account/user_appointments"
        btnTxt="Back To Appointments List"
      />
      {SelectedService !== null && <div>{steps[current].content}</div>}
    </>
  );
}
